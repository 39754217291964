import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import WAREHOUSE_ICON from '../images/content/company-news/warehouse.jpg';

const CompanyNews = () => {
  return (
    <>
      <SEO
        title={`Company News`}
        />
      <Layout>
        <div
          className="row column"
        >
          <div className="row">
            <div className="medium-6 columns mb6">
              <div>
                <Link to="/company-news/surplus-solutions-expansion-new-warehouse-murrieta-ca"><img
                    src={WAREHOUSE_ICON}
                    alt="Murrieta Warehouse"
                  /></Link>
              </div>
            </div>
            <div
              className="medium-6 columns"
            >
              <h2 className="h1">Surplus Solutions Announces Major Expansion</h2>
              <hr />
              <p>
              Surplus Solutions Announces Major Expansion of Operations with New 37,000 Sq Ft Warehouse in Murrieta, CA
              </p>
              <p className="mt6">
                <Link
                  to="/company-news/surplus-solutions-expansion-new-warehouse-murrieta-ca"
                  className="button secondary tiny wide caps"
                >
                  Learn More
                </Link>
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default CompanyNews;

